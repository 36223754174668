@font-face {
  font-family: 'Rusty Hooks Regular';
  src: local('Rusty Hooks Regular'), url('./fonts/rusty-hooks-font/RustyHooksRegular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Mario';
  src: local('Mario'), url('./fonts/mario/mario.otf') format('truetype');
  font-weight: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #170e24;
  background-size: 100%, 100%;
  background-repeat: repeat-y;
}

code {
  font-family: "Montserrat";
}